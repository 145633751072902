.manage--wrapper {
  background: #fefefe;
  border-radius: 2px;
  padding: 2rem;
  margin-bottom: 2rem;
}

.manage--wrapper > h1 {
  font-size: 2rem;
}
