.manage--wrapper {
    background: #fefefe;
    /* height: 100%; */
    borderradius: 2px;
    padding: 2rem;
    margin-bottom: 2rem;
  }
  
  .manage--wrapper > h1 {
      font-size: 2rem;
  }