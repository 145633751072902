.wrapper {
    border: 1px solid #42c0d9;
    borderRadius: 2px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: 600px;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}