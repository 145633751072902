.logo > span {
  display: flex;
  align-items: center;
  color: white;
}

.logo {
  padding: 0rem 1rem;
  background: rgba(255, 255, 255, 0.2);
  float: left;
}

.ant-layout {
  background: #7f7fd5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #91eae4,
    #86a8e7,
    #7f7fd5
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
  to top,
  #001529,
  #003b4d,
  #006080
);
 /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}