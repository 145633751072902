[data-theme="compact"]
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: #f7f7f7;
  border-radius: 2px;
  margin-bottom: 24px;
  border: 0px;
  overflow: hidden;
}
